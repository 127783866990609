import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import next from "../assets/img/next.svg";
import * as ReactBootStrap from "react-bootstrap";
import { Link } from "react-router-dom";
import services from "../api/services";
function Cards(props) {
  // useEffect(() => {
  //   console.log(props);
  // }, []);
  const history = useHistory();

  return (
    <div key={props.link}>
      <ReactBootStrap.Card
        className="pt-5 justify-content-center text-center col-lg-4 boxy"
        style={{
          maxWidth: "100%",
          width: "20rem",
          height: "400px",
          border: "0",
        }}
      >
        <Link to={`/services/${props.link}`} style={{ textDecoration: "none" }}>
          {" "}
          <ReactBootStrap.Card.Img
            
            style={{objectFit:"contain",height: "100px" }}
            variant="top"
            src={`https://dynamo.jugadu.in${props.img}`}
          />
          <ReactBootStrap.Card.Body>
            <ReactBootStrap.Card.Title>
              {" "}
              <h4 style={{ color: "#2b4b80" }}>{props.title} </h4>
            </ReactBootStrap.Card.Title>
            <ReactBootStrap.Card.Text style={{ color: "black" }}>
              {props.description}
            </ReactBootStrap.Card.Text>
          </ReactBootStrap.Card.Body>
          {/* <Link
          to={`/services/${props.link}`}
          className="pt-3"
          style={{ color: "#7b40c0", textDecoration: "none" }}
          // onClick={() => history.replace(`/services/${props.link}`)}
        > */}
          <span
            className="pt-3"
            style={{ color: "#7b40c0", textDecoration: "none" }}
          >
            <img
              src={next}
              style={{ height: "20px", textDecoration: "none" }}
            />{" "}
            Learn More
          </span>
          {/* </Link> */}
        </Link>
      </ReactBootStrap.Card>
    </div>
  );
}
export default Cards;
