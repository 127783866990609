// export default Footer;
import React from "react";
import * as ReactBootStrap from "react-bootstrap";
import { Link, BrowserRouter as Router } from "react-router-dom";
import Business from "../container/business";
import "../CSS/Footer.css";
// import Logo from "./Logo_holder.png";

function Footer() {
    return (
        <>
        <div style={{ backgroundColor: "#f5f5f5" }} className="Footer pt-5 pb-3">
            
                <div className="row justify-content-center m-0 p-0">
                    <h2 style={{ fontFamily: "'Lexend Giga', sans-serif", fontWeight: "550" }}>NLINK</h2>
                </div>

                <div className="mt-4">
                    <p style={{ color: "grey" }} className="text-center">#123 SS colony Street No-3  </p>
                    <p style={{ color: "grey" }} className="text-center">District- Sirsa Pincode-125055 Haryana, India</p>
                    <p style={{ color: "grey" }} className="text-center">Mobile- 9996768084</p>
                </div>
        </div>
        <div className="bg-secondary text-white">
            <p style={{ fontWeight: "100" }} className="pt-4 text-center">Designed and developed by <a style={{ color: "black" }} href="https://www.jugadu.in/">Jugadu.in</a></p>
            <p style={{ fontWeight: "100" }} className="pt-2 pb-3 text-center">Powered by <a style={{color: "black"}} href="https://jugadu.in/jugaduBoost">Jugadu BOOST</a></p>
        </div>
        </>
    )
}
export default Footer;