import React from 'react'
// import { Card } from 'react-bootstrap';
// import * as ReactBootStrap from "react-bootstrap"
import LoginForm from './loginform.js'

const LoginComponent = () => 
    <div><LoginForm /></div>


export default LoginComponent;
