import React from 'react'
import {Card , Form , Button,Container} from 'react-bootstrap';


// const Login = () => (
    function Login() {
        return (
            <>
            {" "}
            {/* <div>
                <Container className="d-flex align-items-center justify-content-center" style={{minHeight:"100vh"}}>
                    <div className="w-100" style={{maxWidth:"400px"}}>
                        <Card>
                            <Card.Body>
                                <h2 className="text-center mb-4">Sign Up</h2>
                                <Form>
                                    <Form.Group id="email">
                                        <Form.label>Email</Form.label>
                                        <Form.Control type="email" required/>
                                    </Form.Group>
                                    <Form.Group id="password">
                                        <Form.label>Password</Form.label>
                                        <Form.Control type="password" required/>
                                    </Form.Group>
                                    <Button className="w-100"  type="submit">Login In</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>            
            </div>   */}
            <h2>LOGIN</h2>
        </>
        )
    }

export default Login;
