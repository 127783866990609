import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import classes from "../CSS/contact.module.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button } from "react-bootstrap";
import Footer from "../component/footer";
import { FaHome } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import emailIcon from "@iconify-icons/carbon/email";

//import outlineContactMail from '@iconify-icons/ic/outline-contact-mail';

const useStyles = makeStyles(() => ({
  root: {
    background: "white",
    padding: "0 1%",
    borderRadius: "10px",
  },
  input: {
    color: "black",
    padding: "1%",
  },
}));

const ContactUs = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const myclasses = useStyles();
  return (
    <>
      <div
        className={classes.Contact}
        id="contact"
        style={{ paddingTop: "130px" }}
        class="container"
      >
        <h1
          
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            color: "brown",
            fontFamily: "Lexend Giga",
          }}
        >
          CONTACT US{" "}
          <Icon icon={emailIcon} style={{ height: "60px", width: "40px" }} />{" "}
        </h1>

        <br />
        <h3
          
          style={{ textAlign: "center", color: "black" }}
        >
          Contact <span style={{ color: "brown" }}>NLINK</span> to get the Best
          Services at the lowest price in the entire industry.
        </h3>
        <br />
        <br />
        <div className="row m-0 p-0 d-flex justify-content-center align-items-center">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <h4
              style={{ color: "black", fontWeight: "bolder" }}>
              Reach us at:
            </h4>
          </div>
          <div className="col-12 m-0 p-0">
            <div className="row d-flex justify-content-between align-items-center">
              <div className="col-md-4 col-12 d-flex justify-content-center align-items-center p-2">
                <FaHome size="2rem" color="black" />
                <span
                  className="pl-2"
                  style={{ color: "#2a2a2a", fontWeight: "500" }}>
                   Haryana,India
                </span>
              </div>
              <div className="col-md-4 col-12 d-flex justify-content-center align-items-center p-2">
                <FaMobileAlt size="2rem" color="black" />
                <span
                  className="pl-2"
                  style={{ color: "#2a2a2a", fontWeight: "500" }}>
                  9996768084
                </span>
              </div>
              <div className="col-md-4 col-12 d-flex justify-content-center align-items-center p-2">
                <AiOutlineMail size="2rem" color="black" />
                <span
                  className="pl-2"
                  style={{ color: "#2a2a2a", fontWeight: "500" }}>
                  info@nlink.com
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>{/* <Footer/> */}</div>
    </>
  );
};
export default ContactUs;
