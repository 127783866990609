import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import service from "../api/service";
import DOMPurify from 'dompurify';
import "../CSS/index.css";

function Template() {
  //   let location = useHistory();
  //   console.log(location);
  useEffect(() => {
    console.log("mounted template");
  }, []);
  const [service1, setService1] = useState([]);
  const { serviceId } = useParams();
  console.log(serviceId);
  useEffect(() => {
    service.get(`/${serviceId}`).then((response) => {
      console.log(response.data);
      setService1(response.data);
    });
  }, []);
    
  return (
    <div className="">
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      ></meta>
      <div className="pb-5 container">
        <div className=" row">
          <div className="col-lg-6 pt-5">
            <img
              id="optionalstuff"
              className="w3-animate-left img-fluid col-sm-0 sticky-image"
              src={"https://dynamo.jugadu.in" + service1.banner_url}
              style={{ height: "400px", paddingTop: "", paddingLeft: "",objectFit:"contain"}}
            />
          </div>
          <div className="col-lg-6 pt-5">
            <h1 style={{ fontFamily: "'Rubik', sans-serif", color: "#3F3F3F" }}>
              {service1.title}
            </h1>
            {/* <p style={{color:"#8F8F8F"}}></p> */}{" "}
            <div dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(service1.description)}} style={{ color: "#8F8F8F" }}/>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  );
}
export default Template;
